<template>
  <div class="fragment">

    <template v-if="$store.getters.getHeartysanCategory.length > 0 && $store.getters.getHeartysanCategoryLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'heartysan_HeartysanCategoryId',
          'heartysan_HeartysanCategoryName',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('heartysan_HeartysanCategoryId.localization_value.value')}}</th>
            <th>{{$t('heartysan_HeartysanCategoryName.localization_value.value')}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getHeartysanCategory" :key="index">
            <td>
              <div v-bind:class="{'pl-5': item.parent_id}">
                #{{item.id}}
              </div>
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'currentTranslate.name'"
              />
            </td>
            <td>
              <ManagerButton class="right ml-auto">
                <template slot="item">
                  <LinkButton
                      :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      :link="$store.getters.GET_PATHS.heartysanCategory + '/edit/' + item.id"
                  />
                </template>
<!--                <template slot="item">-->
<!--                  <LinkButton-->
<!--                      :value="$t('common_delete.localization_value.value')"-->
<!--                      :type="'delete'"-->
<!--                      @click.native="removeItem(item.id)"-->
<!--                  />-->
<!--                </template>-->
              </ManagerButton>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getHeartysanCategory" :key="index"
            >
              <HeartysanCategoryMobileTable
                      :item="item"
                      @removeItem="removeItem(item.id)"
                      @changeInfoPopup="changeInfoPopup(true, item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getHeartysanCategoryCommonList.next_page_url !== null && $store.getters.getHeartysanCategory.length > 0"
                    @click.native="$emit('showMore')"
                    :count="$store.getters.getHeartysanCategoryCommonList.total - $store.getters.getHeartysanCategoryForPage < $store.getters.getHeartysanCategoryForPage ?
                  $store.getters.getHeartysanCategoryCommonList.total - $store.getters.getHeartysanCategoryForPage:
                  $store.getters.getHeartysanCategoryForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getHeartysanCategoryLoading === false && $store.getters.getHeartysanCategory.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import HeartysanCategoryMobileTable from "./HeartysanCategoryMobileTable/HeartysanCategoryMobileTable";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";


  export default {
    name: "HeartysanCategoryTable",

    components: {
      ManagerButton,
      HeartysanCategoryMobileTable,
      LinkButton,
      ValueHelper,
      NoResult,
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: '',
        showFilter: false,
        isMobile: false,


        showContent: false,

        show1: false,
        show2: false,
        show3: false,

        isModalInfoPopup: false,
      }
    },


    methods: {

      changeInfoPopup(val, itemId = false){
        if(itemId){
          this.itemId = itemId
        } else {
          this.itemId = ''
        }
        this.isModalInfoPopup = val
      },

      getExpenseTypeName(item, systemType) {
        return this._.find(item.expense_types,
            function(elem) {return elem.type === systemType})?.name || '—'
      },

      removeItem(id){
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteHeartysanCategory', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let expenses = this.$store.getters.getHeartysanCategory
              expenses.map((item, index) => {
                if(item.id === id) {
                  expenses.splice(index, 1)
                }
              })

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    },

  }
</script>

<style lang="scss" scoped>

  .table-row.justify-content-end {
    transform: translateX(7px);
  }

  .mw-220 {
    max-width: 220px !important;
  }
</style>
