<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number cursor-pointer"
      >
        #{{item.id}}
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  :item="{user: item}"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  export default {
    name: "HeartysanUsersMobileTable",
    components: {TableUserColumn, },

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
