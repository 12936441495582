<template>
  <div>
    <HeartysanUsersHead
        :navTabs="navTabs"
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @changeTab="(data) => $emit('changeTab', data)"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

      <HeartysanUsersFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <HeartysanUsersTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import HeartysanUsersHead from "./HeartysanUsersHead/HeartysanUsersHead";
  import HeartysanUsersTable from "./HeartysanUsersTable/HeartysanUsersTable";
  import HeartysanUsersFilter from "./HeartysanUsersFilter/HeartysanUsersFilter";

  export default {
    name: "HeartysanUsersTableBlock",

    components: {
      HeartysanUsersHead,
      HeartysanUsersTable,
      HeartysanUsersFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
      // categoryLoaded: Boolean,
      // active: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>


