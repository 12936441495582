<template>
  <HeartysanUsersTableBlock
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          :navTabs="navTabs"
          @changeTab="changeTab"
          @resetFilter="resetFilter"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @reload="reload"
  />
<!--          :active="_.find(navTabs, {active: true})"-->
<!--          :categoryLoaded="categoryLoaded"-->
</template>

<script>
  import HeartysanUsersTableBlock from "./HeartysanUsersTableBlock/HeartysanUsersTableBlock";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "HeartysanUsersTable",
    components: {
      HeartysanUsersTableBlock,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getHeartysanUsersForPage,
        page: 1,
        onePage: 1,

        filterType: '',
        filterUserId: '',
        filterUserName: '',

        navTabs: {
          manufacturer: {
            name: 'manufacturer',
            label: this.$t('heartysan_UsersManufacturer.localization_value.value'),
            value: 'manufacturer',
            active: true,
            id: 1
          },
          seller: {
            name: 'seller',
            label: this.$t('heartysan_UsersSeller.localization_value.value'),
            value: 'seller',
            active: false,
            id: 2
          },
        },

        countFilterParams: 0,
        filterGetParams: {},

        // categoryLoaded: false,
      }
    },

    mounted() {
      // let myQuery = this.createQueryBuilderObject()
      // let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
      // this.$store.dispatch('fetchHeartysanUsersResponse', url).then(response => {
      //   let categories = this.getRespPaginateData(response)
      //   categories.map((category) => {
      //     if(category.parent_id === 0) {
      //       this.navTabs[category.id] = {
      //         name: category.currentTranslate.name,
      //         label: category.currentTranslate.name,
      //         active: false,
      //         value: category.id,
      //       }
      //     }
      //   })
      //
      //   this.navTabs[Object.keys(this.navTabs)[0]].active = true
      //   this.categoryLoaded = true
      // })

      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          type: this.$route.query.type,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextHeartysanUsersPage', true)

        this.$store.dispatch('fetchHeartysanUsers', url).then(() => {
          this.$store.commit('setNextHeartysanUsersPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['type', 'userName', 'userId'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
          myQuery,
          {
            // filterId: 'user_id',
            filterUserId: 'user_id',
            // filterType: 'ExpenseComment',
          },
        )
        let activeTab = this._.find(this.navTabs, {active: true})
        let type = activeTab.id
        myQuery.where('type_id', type)

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }


  }
</script>

<style scoped>

</style>
