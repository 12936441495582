<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_HeartysanCategoryId',])"></div>
        <DefaultInput
                :label="$t('heartysan_HeartysanCategoryId.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['heartysan_HeartysanCategoryName',])"></div>
        <DefaultInput
                :label="$t('heartysan_HeartysanCategoryName.localization_value.value')"
                :type="'text'"
                v-model="name"
        />
      </div>





    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";

  export default {
    name: "HeartysanCategoryFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc],

    data() {
      return {
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        name: this.filterGetParams.name ? this.filterGetParams.name : '',

        filterCounts: [
          'id',
          'name',
        ],
      }
    },

    created() {
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.name = newVal.name ? newVal.name : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
