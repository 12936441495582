<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line content-top-line--bold" >
      <vue-custom-scrollbar class="calc-ship__content-scrollbar site-custom-scroll-x" :settings="settings">
        <div class="content-tabs content-tabs--separator">
          <template v-for="(item, index) in navTabs">
            <div class="content-tabs__item white-space-nowrap" style="max-width: initial !important; min-width: initial !important;"
                 :key="index + active"
                 v-bind:class="{active: item.active}"
                 @click="$emit('changeTab', item.value)"
            >
              <div class="content-tabs__ico">
                <template v-if="item.ico">
                  <img :src="item.ico" alt="company-ico" :width="item.icoWidth">
                </template>
                <template v-else>
                  {{item.label}}
                </template>
              </div>
            </div>
          </template>
        </div>
      </vue-custom-scrollbar>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                :showFilter="showFilter"
                @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">

      </div>
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddNew'])"></div>
        <router-link :to="$store.getters.GET_PATHS.heartysanCategoryCreate">
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import vueCustomScrollbar from 'vue-custom-scrollbar';
  import "vue-custom-scrollbar/dist/vueScrollbar.css";


  export default {
    name: "HeartysanCategoryHead",
    components: {
      ToggleFilterButton,
      PlusIconSVG,
      MainButton,
      vueCustomScrollbar,
    },

    props: {
      navTabs: Object,
      showFilter: Boolean,
      countFilterParams: Number,
      active: Object,
    },

    watch: {
      navTabs(val) {
        console.log(23423);
        console.log(val);
      }
    },

    created() {

    },

    data() {
      return {
        settings: {
          suppressScrollY: true,
          suppressScrollX: false,
          wheelPropagation: false,
          useBothWheelAxes: true,
          wheelSpeed: 0.5,
          swipeEasing: true,
          scrollXMarginOffset: 16,
        },
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

.content-top-line--bold >>> .site-custom-scroll-x .ps__thumb-x{
  height: 5px !important;
}

.content-top-line--bold >>> .ps__rail-x:focus .ps__thumb-x,
.content-top-line--bold >>> .ps__rail-x:hover .ps__thumb-x{
  height: 5px !important;
}

.content-tabs--separator{
  overflow: initial;
  width: initial;
}

.calc-ship__content-scrollbar >>> .ps__rail-x {
  bottom: 13px !important;
  height: 9px !important;
}

.calc-ship__content-scrollbar {
  width: calc(100% - 170px);
}

@media (max-width: 1150px) {
  .calc-ship__content-scrollbar {
    width: calc(100% - 60px);
  }
}

@media (max-width: 768px) {
  .calc-ship__content-scrollbar {
    width: 100%;
  }
}
</style>

