<template>
  <HeartysanCategoryTableBlock
      v-if="categoryLoaded"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      :navTabs="navTabs"
      :active="_.find(navTabs, {active: true})"
      :categoryLoaded="categoryLoaded"
      @changeTab="changeTab"
      @resetFilter="resetFilter"
      @changeFilter="changeFilter"
      @showMore="showMore"
      @reload="reload"
  />
</template>

<script>
import HeartysanCategoryTableBlock from "./HeartysanCategoryTableBlock/HeartysanCategoryTableBlock";
import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

export default {
  name: "HeartysanCategoryTable",
  components: {
    HeartysanCategoryTableBlock,
  },

  mixins: [routeFilter],

  data() {
    return {
      forPage: this.$store.getters.getHeartysanCategoryForPage,
      page: 1,
      onePage: 1,

      filterType: '',
      filterId: '',
      filterName: '',

      navTabs: {
        0: {
          name: 'all',
          label: this.$t('heartysan_allCategories.localization_value.value'),
          active: false,
          value: '0',
        }
      },

      countFilterParams: 0,
      filterGetParams: {},

      categoryLoaded: false,
    }
  },

  mounted() {
    let myQuery = this.createQueryBuilderObject()
    let url = this.generateGetParamsFromQuery(myQuery, 1000000, 0)
    this.$store.dispatch('fetchHeartysanCategoryResponse', url).then(response => {
      let categories = this.getRespPaginateData(response)
      categories.map((category) => {
        if (category.parent_id === 0) {
          this.navTabs[category.id] = {
            name: category.currentTranslate.name,
            label: category.currentTranslate.name,
            active: false,
            value: category.id,
          }
        }
      })

      this.navTabs[Object.keys(this.navTabs)[0]].active = true
      this.filter()
    }).catch(error => this.createErrorLog(error))
  },

  methods: {

    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,

        id: this.$route.query.id,
        name: this.$route.query.name,
        type: this.$route.query.type,
      }
    },

    refreshTable() {
      this.filter()
    },

    filter(next = false) {
      this.getFilterParam()

      let url

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
      )

      if (next) this.$store.commit('setNextHeartysanCategoryPage', true)
      this.$store.dispatch('fetchHeartysanCategory', url).then(() => {
        this.$store.commit('setNextHeartysanCategoryPage', false)
        this.$store.commit('setGlobalUpdateTable', false)
        this.categoryLoaded = true
      })


      this.checkCountFilter(['type'])
    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject()

      this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'id',
            filterName: 'translatedName',
          },
      )

      if(!this.navTabs[0].active) {
        let activeTab = this._.find(this.navTabs, {active: true})
        let type = activeTab.value
        myQuery.where('parent_id', type)
      }

      // myQuery.where('ExpenseType', 'expense')

      return this.generateGetParamsFromQuery(myQuery, forPage, page)


    },

  }


}
</script>

<style scoped>

</style>
