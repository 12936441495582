<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import {EXPENSES_SYSTEM_TYPES} from "../../../../../../../staticData/staticVariables";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";

  export default {
    name: "HeartysanUsersFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc, usersFunc],

    data() {
      return {
        id: this.filterGetParams.id ? this.filterGetParams.id : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        options: [],

        filterCounts: [
          'id',

          'userName',
          'userId',
        ],
      }
    },

    created() {

    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },


    },
  }
</script>

<style scoped>

</style>
