<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number cursor-pointer"
           @click="changeInfoPopup(true)"
      >
        #{{item.id}}
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('expenses_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('expenses_Total.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.amount"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('expenses_warehouse.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'warehouse.sender_city'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('common_country.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'country.name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('expenses_bank.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('expenses_TypeHeartysanCategory.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('expenses_payee.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('expenses_Comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TooltipTextHelper
                  :text="item.comment"
                  :paragraph="item.comment"
                  :fullTextWidth="'120'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col d-flex">
            <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.incomeHeartysanCategoryEdit + '/' + item.id"
            />
            <LinkButton
                    class="ml-3"
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeItem')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  export default {
    name: "HeartysanCategoryMobileTable",
    components: {TooltipTextHelper, LinkButton, ValueHelper},

    props: {
      item: Object,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {
      getExpenseTypeName(item, systemType) {
        return this._.find(item.expense_types,
            function(elem) {return elem.type === systemType})?.name || '—'
      },
    }
  }
</script>

<style scoped>

</style>
