<template>
  <div class="fragment">

    <template v-if="$store.getters.getHeartysanUsers.length > 0 && $store.getters.getHeartysanUsersLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'heartysan_HeartysanUsersId',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('common_user.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getHeartysanUsers" :key="index">
            <td>
              <TableUserColumn
                  :item="{user: item}"
              />
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getHeartysanUsers" :key="index"
            >
              <HeartysanUsersMobileTable
                      :item="item"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getHeartysanUsersCommonList.next_page_url !== null && $store.getters.getHeartysanUsers.length > 0"
                    @click.native="$emit('showMore')"
                    :count="$store.getters.getHeartysanUsersCommonList.total - $store.getters.getHeartysanUsersForPage < $store.getters.getHeartysanUsersForPage ?
                  $store.getters.getHeartysanUsersCommonList.total - $store.getters.getHeartysanUsersForPage:
                  $store.getters.getHeartysanUsersForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getHeartysanUsersLoading === false && $store.getters.getHeartysanUsers.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import HeartysanUsersMobileTable from "./HeartysanUsersMobileTable/HeartysanUsersMobileTable";
  import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";


  export default {
    name: "HeartysanUsersTable",

    components: {
      TableUserColumn,
      HeartysanUsersMobileTable,
      NoResult,
      ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        itemId: '',
        showFilter: false,
        isMobile: false,


        showContent: false,

        show1: false,
        show2: false,
        show3: false,

        isModalInfoPopup: false,
      }
    },


    methods: {

    },

  }
</script>

<style lang="scss" scoped>

  .table-row.justify-content-end {
    transform: translateX(7px);
  }

  .mw-220 {
    max-width: 220px !important;
  }
</style>
